<template>
  <div class="max-content">
    <div class="me-wrap dfr mdfc por">
      <div class=" me-left">
        <div class="inner-left mdfr ">
          <div
            v-for="(item, index) in leftTabs"
            :key="index"
            @click="activeItem(index)"
            class="left-tabs dfr pointer  cross-center"
            :class="[item.active ? 'active' : '']"
          >
            <div class="icon-box">
              <img v-if="item.active" :src="item.activeIcon" alt="" />
              <img v-else :src="item.icon" alt="" />
            </div>
            <div :class="[item.active ? 'fctheme' : 'fc666']" class="name fs16">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="me-right">
        <me-contact></me-contact>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftTabs: [
        {
          name: "My Info",
          icon: require("../assets/images/me.png"),
          activeIcon: require("../assets/images/me-active.png"),
          id: 1,
          active: true
        }
        // {
        //   name: "My Application",
        //   icon: require("../assets/images/me-app.png"),
        //   activeIcon: require("../assets/images/me-app-active.png"),
        //   id: 2,
        //   active: false
        // }
      ],
      currentActiveIndex: 0,
      langText: {
        title: "My Account"
      }
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.me;
      } else if (lang == "CN") {
        this.langText = this.$CN.me;
      }
      this.leftTabs[0].name = this.langText.contactUs.title;
    },
    activeItem(index) {
      this.currentActiveIndex = index;
      this.leftTabs.map(item => {
        item.active = false;
      });
      this.leftTabs[index].active = true;
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.max-content {
  background: #fafafc;
  padding: 30px 0;
}
.me-wrap {
  width: 1200px;
  margin: 0 auto;
  max-width: 90%;
  .me-left {
    width: 250px;
    height: 100%;
    margin-right: 20px;
    .inner-left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 250px;
      background: #fff;
      padding: 30px 0;
      border-radius: 6px;
    }
    .left-tabs {
      height: 50px;
      box-sizing: border-box;
      padding: 0 30px;
      margin-bottom: 4px;
      &.active {
        background: #f6fafd;
      }
      &:hover {
        background: #f6fafd;
      }
      .icon-box {
        width: 18px;
        height: 18px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
  .me-right {
    flex: 1;
  }
}
@media screen and (max-width: 767px) {
  .me-wrap {
    .me-left {
      margin-bottom: 60px;
      position: relative;
      width: 100%;
      margin-right: 0;

      .inner-left {
        height: 100%;
        width: 100%;
        padding: 0;
        display: flex;
      }
    }
    .me-right {
      width: 100%;
    }
    .me-account-wrap {
      width: 100%;
    }
  }
}
</style>
